.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: var(--white);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;

    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .pageButton {
    background: var(--white);
    border: none;
    color: var(--dark-grey);
    padding: 10px 15px;
    margin: 0 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    transition: all 0.3s ease;

    border-radius: 5px;
  }
  
  .pageButton:hover {
    background: var(--light-grey);
  }
  
  .pageButton.active {
    background: var(--jg-red);
    color: var(--white);
    font-weight: 700;
  }
  
  .arrowButton {
    background: var(--white);
    border: none;
    color: var(--dark-grey);
    padding: 10px 15px;
    margin: 0 5px;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.3s ease;
  }
  
  .arrowButton:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .arrowButton:hover:not(:disabled) {
    background: #f0f0f0;
  }
  
  .ellipsis {
    margin: 0 5px;
    font-size: 16px;
    color: #555;
  }
  