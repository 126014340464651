/* src/components/Slide/Slide.module.css */
.slideContainer {
  width: 100%;
  padding-top: 28.5714%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  cursor: pointer;
}

.coverImage{
  height: 100%;
  width: 100%;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}


.shadow{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  height: 50px;
  background-color: var(--black);
  opacity: 0.4;
}

.textContainer{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.slideDescription{
  margin: 0;
  text-align: center;

  color: white;
  font-weight: bold;
  font-size: 20px;
}

.slideDescriptionLoading{
  color: transparent
}

@media (max-width: 800px) {
  .slideDescription{
    font-size: 14px;
  }
  .shadow{
    height: 30px;
  }
  .textContainer{
    height: 30px;
  }
}

@media (max-width: 1400px) {
  .coverImage{
    background-size: contain;
  }
}