.container {
    display: flex;
    align-items: center;
    cursor: pointer;

    padding: 5px;
  }
  
  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .text {
    margin: 0;
  }
  
  .gold {
    background-color: var(--gold);
  }
  
  .green {
    background-color: var(--green);
  }
  
  .blue {
    background-color: var(--blue);
  }
  
  .light-blue {
    background-color: var(--light-blue);
  }
  
  .pink {
    background-color: var(--pink);
  }
  
  .violet {
    background-color: var(--violet);
  }
  
  .bold {
    font-weight: 700;
  }
  
  .normal {
    font-weight: 400;
  }