.container {
  /* height: 30px; */
  /* width: 300px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.hiddenLabel{
  visibility: hidden;
}
.searchInput {
  height: 34px;
  width: 250px;

  padding: 0;
  border: 1px solid var(--grey);
  border-right: none;

  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  padding-left: 10px;

  font-size: 16px;
}

.searchInput:focus {
  outline: none;
  border: 1px solid var(--grey);
  border-right: none;
}

.searchButton {
  height: 36px;
  width: 40px;
  border: none;
  background-color: var(--jg-red);
  color: var(--white);

  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  cursor: pointer;
}

.searchButton svg {
  font-size: 18px;
}
