:root {
  --black: #000;
  --blue: #00f;
  --dark-blue: #3b5998;
  --dark-grey: #777;
  --gold: #ffa500;
  --green: #228b22;
  --grey: #bbbbbb;
  --jg-red: #d50107;
  --light-blue: #add8e6;
  --light-grey: #d9d9d9;
  --pink: #ea85b1;
  --violet: #cf9fff;
  --white: #fff;
}
