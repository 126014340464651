p{
    margin: 0 !important;
    padding: 0 !important;

}

.container{
    box-shadow: 0px 0px 4px 1px var(--light-grey);

    margin: 10px 0 !important;
}

.image{

    background-color: #ebebeb;

    width: 250px;
    height: 250px;
}

.imageMockup{
    height: 250px;
    width: 100%;
  }

.link{
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
  
    text-decoration: none;
  
    cursor: pointer;
  }
  .link:hover{
    color: var(--black);
  }
  .link:link {
    color: var(--black); /* Set the same color as the surrounding text */
  }
  
  /* Ensure clicked links are not red */
  .link:visited {
    color: var(--black); /* Set the same color as the surrounding text */
  }
  
  .link:hover {
    color: var(--black); /* Optionally change color on hover */
  }

  .image{
    flex-basis: 100%;
  }

  .imageContainer{
    height: 250px;
    width: 250px;
    background-color: #ebebeb;

    position: relative;
  }

  .contentContainer{
    flex-grow: 1;

    height: 240px;
  
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 5px;
    padding-left: 10px;
  }

  .title{
    font-weight: bold;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .contentContainer div{
    padding-top: 5px;
  
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    overflow: hidden;
  }


  .contentContainer div p{
  line-height: 20px;
  }
  
  .contentContainer p{
    word-break: break-word;
  }

  .descriptionContainer p{
    display: -webkit-box;
    -webkit-line-clamp: 7; /* Number of lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
  }
  
  
  .date{
    padding-top: 15px !important;
    padding-bottom: 2px !important;
  
    font-style: italic;
    color: var(--dark-grey)
  }

@media (max-width: 600px) {
  .link{
    flex-wrap: wrap;
  }
  .imageContainer{
    width: 100%;
    height: auto;
    min-height: 100px;
  }
  .image{
    width: 100%;
    height: auto;
  }
  .contentContainer{
    padding-left: 5px;
  }
}


  