.footer {
  background-color: var(--black);
}

.container {
  max-width: 1000px;
  margin: 0 auto !important;

  display: flex;
  justify-content: space-between;

  position: relative;
}

.logoContainer {
  height: 150px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.logo {
  height: 40px;
}
.contactContainer {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 0;
}

.title {
  margin: 0;
  text-align: center;
  height: 40px;

  display: flex;
  justify-content: left;
  align-items: center;
}

.title h2 {
  color: var(--white);
}

.contactAdress {
  /* height: 110px; */

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: top;
  row-gap: 10px;

  color: white;
}
.contactAdress p {
  width: 100%;

  text-align: left;
}
.contactPhoneMail {
  /* height: 110px; */

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: top;
  row-gap: 10px;

  color: white;
}
.contactPhoneMail p {
  width: 100%;
  text-align: left;
}

.contactPhoneMail a {
  width: 100%;
  text-align: left;
  text-decoration: none;
  color: var(--white)
}

.contactPhoneMail p svg {
  padding-right: 5px;
}
.contactPhoneMail a svg {
  padding-right: 5px;
}

.socialsContainer {
  height: 130px;

}

.socialsContent {
  display: flex;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: flex-start;
  column-gap: 40px;
}

.socialsItem{
}

.socialsItem a {
  display: flex;

  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  text-decoration: none;

  color: var(--white);
}

.socialsItem a svg {
  height: 30px;
  width: 0px;
  flex-basis: 100%;
  margin-bottom: 5px;
}

.socialsItem a p {
  /* flex-basis: 100%; */
  text-align: left;
  padding-top: 5px;
}

.cookieContainer{
  width: 100%;
  text-align: center;
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
}

.cookieContainer a{
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  color: white;
}

@media (max-width: 1200px) {
  .container{
    max-width: 800px;
  }
  .logoContainer {
    height: 80px;
  }
  /* .socialsContent{
    align-items: start;
  }
  .socialsContainer {
    height: 150px;
  } */
  .contactContainer {
    /* height: 150px; */
  }

  /* .contactAdress{
    height: 100px;
  }
  .contactPhoneMail{
    height: 100px;
  } */
  /* .contactContainer {
    justify-content: space-around;
  }

  .title{
    justify-content: center;
  } */
  /* .contactAdress p{
    text-align: center;
  } */
  /* .contactPhoneMail p {
    text-align: center;
  } */
  /* .contactPhoneMail a {
    text-align: center;
  } */
  /* .socialsContent {
    justify-content: center;
  }
  .socialsItem a p {
    text-align: center;
  } */
}

@media (max-width: 900px) {
  .contactContainer {
    justify-content: center;
    row-gap: 20px;
  }
  .contactPhoneMail a {
    text-align: center;
  }
  .contactAdress{
    row-gap: 7px;
  }
  .contactAdress p {
    text-align: center;
  }
  .contactPhoneMail{
    row-gap: 10px;
    margin-bottom: 15px;
  }
  .contactPhoneMail p {
    width: 100%;
    text-align: center;
  }
  .title{
    justify-content: center;
    height: 20px;
  }
  .socialsContent{
    justify-content: center;
  }
}

/*
xs: Extra-small screens (0px and up)
sm: Small screens (600px and up)
md: Medium screens (900px and up)
lg: Large screens (1200px and up)
xl: Extra-large screens (1920px and up)
*/
