.sliderContainer {
  width: 100%;
  height: auto;

  /* margin-bottom: 40px; */
  margin-top: 10px;
}

@media (max-width: 600px) {
  .sliderContainer{
    display: none;
  }
}