.container {
  height: 40px;
  width: 100%;
  padding: 10px 0;
  position: relative;
  margin-bottom: 10px;
}
.splitter {
  height: 2px;
  width: 100%;
  background-color: var(--grey);

  position: absolute;
  top: 50%;
  transform: translateY(-50%)
}
.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);


  padding: 10px 40px;

  color: var(--dark-grey);
  background-color: var(--white);

  text-align: center;
}

@media (max-width: 674px) {

    .container{
        height: 30px;
        background-color: var(--dark-grey);
    }
    .splitter{
        display: none;
    }
    .title{
        background-color: transparent;
        color: var(--white);

        width: 100%;
    }

}