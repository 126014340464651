/* NotFound.module.css */

.notFoundContainer {
    text-align: center;
    padding: 50px;
  }
  
  .notFoundContainer h1 {
    font-size: 3rem;
    color: var(--black);
  }
  
  .notFoundContainer p {
    font-size: 1.2rem;
    margin-top: 20px;
  }
  
  .homeLink {
    margin-top: 30px;
    display: inline-block;
    padding: 10px 20px;
    background-color: var(--jg-red);
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .homeLink:hover {
    background-color: var(--dark-grey);
  }
  