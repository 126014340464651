.container {
  /* margin-bottom: 10px; */
  margin: 10px;
}
.container * {
  margin: 12px 0;
}
.container h2 {
}
.separator {
  width: 100%;
  height: 1px;
  background-color: var(--light-grey);
}
.author {
  font-weight: 500;
}
.author span {
  color: var(--jg-red);
}

@media (min-width: 1410px) {
  .container{
    margin: 0;
    margin-bottom: 10px;
  }
}