.slideCategory {
  position: absolute;
  background-color: white;
  padding: 5px 10px;
  margin: 0;
  color: white;
}

.slideCategory--manga-rynek-polski {
  background-color: var(--gold);
}

.slideCategory--manga-rynek-zagraniczny {
  background-color: var(--light-blue);
}

.slideCategory--ciekawostki {
  background-color: var(--pink);
}

.slideCategory--konwenty {
  background-color: var(--violet);
}

.slideCategory--anime {
  background-color: var(--green);
}

.slideCategory--inne {
  background-color: var(--blue);
}

.slideCategory--mockup {
  background-color: var(--dark-grey);
  color: var(--dark-grey);
}

.slideCategory--big {
  top: 10px;
  right: 10px;
  font-size: 14px;

  padding: 2px 5px !important;
}

.slideCategory--small {
  top: 0px;
  left: 0;
  right: 0;
  font-size: 13px;
  text-align: center;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

@media (max-width: 800px){
  .slideCategory--big {
    top: 0px;
    right: 0px;
    font-size: 12px;
  }
}
