.container {
  width: 100%;
  height: auto;
}

.context {
  width: 100%;
  height: auto;
  max-height: 450px;

  display: flex;
  justify-content: space-between;

  margin-bottom: 20px;
}
.separator {
  width: 100%;
  height: 1px;
  background-color: var(--light-grey);
}

.img {
  width: 450px;
  height: 450px;

  background-color: #ebebeb;
}
.text {
  overflow-y: auto;
  padding-left: 10px;
}
.text * {
  font-size: 16px !important;
}
.asideNews {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.asideBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  padding-top: 10px;
  padding-bottom: 10px;
}

.asideBanner a {
  width: 49%;
  height: auto;
}

.asideBanner a img {
  height: auto;
  width: 100%;
  box-shadow: 0px 0px 4px 1px var(--light-grey);

  /* height: 300px; */
}

.infoText {
  color: var(--jg-red);
}

@media (max-width: 900px) {
  .context {
    max-height: none;
    width: 100vw;

    flex-wrap: wrap;
  }

  .img {
    width: 100vw;
    height: auto;
  }

  .text {
    overflow-y: none;
    padding-left: 0;
    padding: 10px;
  }
}

.asideNews{
  margin-left: 8px !important;
  margin-right:8px !important;
}

@media (max-width: 700px){
    .asideBanner a:nth-of-type(2){
        display: none;
    }
    .asideBanner a:nth-of-type(1){
        width: 100%;
    }
}
