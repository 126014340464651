.container {
    height: 50px;
    margin: 15px 0;

    display: flex;
    justify-content: center;
    align-items: center;
}
.link {
    background-color: var(--black);
    text-decoration: none;
    font-weight: bold;
    color: var(--white);
    
    width: 100%;
    max-width: 350px;

    border-radius: 5px;

    text-align: center;

    padding: 10px;
    margin: 0 5px;
}
