/* src/components/Navigation/Navigation.module.css */
.navigation {
  height: 100px;
  width: 100vw;
  background-color: var(--black);
  color: var(--white);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.container {
  height: 100%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 70%;
  width: auto;
  /* margin-left: -10px; */
  cursor: pointer;
}

.menu {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuItem {
  padding: 0 20px;
  height: 40px;
  display: flex;
  align-items: center;
}

.menuItem:not(:last-child) {
  border-right: 3px solid var(--jg-red);
}

.menuItem a {
  color: var(--white);
  text-decoration: none;
}

.links svg {
  font-size: 30px;
  transition: opacity 0.3s;
  padding: 0 20px;
}

.hamburgerIcon {
  opacity: 1;
  cursor: pointer;
}

.hamburgerIcon.vanish {
  opacity: 0;
}

.mobileNavigation {
  height: 200px;
  width: 100vw;
  background-color: var(--black);

  position: fixed;
  top: 100px;
  /* right: 0; */

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  transition: right ease 0.2s;

  z-index: 2;
}

.mobileNavigation.open {
  right: 0;
}

.mobileNavigation.close {
  right: -100%;
}

.mobileNavigation a {
  flex-basis: 100%;
  text-decoration: none;
  color: var(--white);
  text-align: center;
}

/*! WYŁĄCZENIE NAWIGACJI PÓKI NIE MA MAGAZYNÓW */
.links{
  display: none !important;
}
.mobileNavigation{
  display: none !important;
}

@media (max-width: 1410px) {
  .container {
    margin: 0 5px;
  }
}

@media (max-width: 768px) {
  .logo {
    height: 50%;
    margin-left: 0px;
  }
}

@media (max-width: 675px) {
  .mobileNavigation {
    /* width: 100vw; */
    /* border-top: 1px solid var(--light-grey); */
  }
}
