@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@import "./variables/color.css";
@import "./variables/fonts.css";
@import "./variables/breakpoints.css";

body {
  font-family: "Lato", sans-serif !important;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  /* display: none; */
}

h1 {
  font-size: 24px;
  margin: 0;
  padding: 0;
}
h2 {
  font-size: 20px;
  margin: 0;
  padding: 0;
}
h3 {
  font-size: 18px;
  margin: 0;
  padding: 0;
}

p.text-big {
  font-size: 16px;
  margin: 0;
  padding: 0;
}

p.text-small {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

p.text-xsmall {
  font-size: 12px;
  margin: 0;
  padding: 0;
}

p.text-caption {
  font-size: 10px;
  margin: 0;
  padding: 0;
}
.slick-dots li button:before {
  font-size: 10px; /* Adjust the size as needed */

  margin-top: 5px;
}

.textTransparent {
  color: transparent;
  background-color: #eee;
}

.textTransparent span {
  color: transparent;
}
.visually-hidden {
  visibility: hidden;
}

.elementLoadingState {
  color: transparent !important;
  background-color: var(--dark-grey) !important;
}

#open-consent-banner {
  background-color: black !important;
}

@media (max-width: 900px) {
  body::-webkit-scrollbar {
    display: none;
  }
}
