:root {
  --3xs: 10px;
  --2xs: 12px;
  --xs: 14px;
  --s: 16px;
  --m: 18px;
  --l: 20px;
  --xl: 22px;
  --2xl: 24px;
  --3xl: 26px;
  --4xl: 28px;
  --5xl: 30px;
}
