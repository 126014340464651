.layout{
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow-x: hidden;
}
.mainContent{
  width: 100%;

  margin-top: 100px;

  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1410px) {
  .mainContent{
    max-width: 100vw;
  }
}