p{
  margin: 0 !important;
  padding: 0 !important;
  padding-right: 5px !important;
}

.container{
  height: 219px;
  box-shadow: 0px 0px 4px 1px var(--light-grey);
}
.link{
  display: flex;
  width: 100%;
  height: 100%;

  text-decoration: none;

  cursor: pointer;
}
.link:hover{
  color: var(--black);
}
.link:link {
  color: var(--black); /* Set the same color as the surrounding text */
}

/* Ensure clicked links are not red */
.link:visited {
  color: var(--black); /* Set the same color as the surrounding text */
}

.link:hover {
  color: var(--black); /* Optionally change color on hover */
}
.contentContainer{
  flex-grow: 1;
  padding-left: 10px;
  padding-right: 5px;


  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title{
  font-weight: bold;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}


.contentContainer div{
  padding-top: 5px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  overflow: hidden;
}
.contentContainer div p{
line-height: 20px;
}

.contentContainer p{
  word-break: break-word;
}


.date{
  padding-top: 10px !important;
  padding-bottom: 2px !important;

  font-style: italic;
  color: var(--dark-grey)
}

.image{
  width: 200px;
  height: 200px;

  min-height: 200px;
  min-width: 200px;

  background-color: #ebebeb;
}

.imageMockup{
  height: 200px;
  width: 200px;
}

.imageContainer{
  position: relative;
  padding-top: 19px;

  height: 200px;
  width: 200px;

  background-color: #ebebeb;
}

.descriptionContainer p{
  display: -webkit-box;
  -webkit-line-clamp: 6; /* Number of lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
}


@media (max-width: 400px) {
  .imageContainer{
  flex-basis: 100% !important;

  height: auto;
  width: auto;
  }
  .container{
    height: auto !important;
  }
  .link{
    flex-wrap: wrap;
  }
  .image{
    width: 100% !important;
    height: auto !important;
  }
  .contentContainer{
    flex-basis: 100% !important;
  }
  .descriptionContainer{
    height: auto !important;
  }
}