.container {
  /* width: 100%; */
  justify-content: space-between;
}
.tagsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70px;
}
.searchbarContainer {
  min-height: 70px;

  display: flex;
  justify-content: right;
  align-items: center;
}
.mainContentContainer {
  min-height: 300px;

  position: relative;
}
.paginationContainer {
}
.informText{
  position: absolute;

  text-align: center;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 600px) {
    .searchbarContainer{
        justify-content: center;
    }
    
}

@media (max-width: 1410px) {
  .container {
    margin: 0 10px !important;
  }
}

