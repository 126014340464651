.container {
  width: 100%;

  margin: 0 !important;
  justify-content: center;
}

@media (min-width: 1410px) {
  .container {
    width: 1415px;
    transform: translateX(-7px);
  }
}

/*todo: zastanwoic sie czy zostaje transform*/
